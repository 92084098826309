@media screen and (max-width:650px) {
    footer{
        padding-right: 40px;
    }
}


@media screen and (max-width:991px) {
    .heading {
        font-size: 40px !important;
        font-weight: 600;
        margin-bottom: 20px;
    }
}


@media screen and (max-width:767px) {
    .heading {
        font-size: 30px !important;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:600px) {
    .heading {
        font-size: 23px !important;
        font-weight: 600;
        margin-bottom: 20px;
    }
     .heading-content {
         font-size: 13px !important;
     }
}

/* @media screen and (max-width:480px) {
    .heading {
        font-size: 17px !important;
        bottom: 23% !important;
        font-weight: 600;
    }

    .heading-content {
        font-size: 10px !important;
    }
}

@media screen and (max-width:390px) {
    .heading {
        font-size: 14px !important;
        bottom: 22% !important;
        font-weight: 600;
    }

    .heading-content {
        font-size: 8px !important;
    }
}
@media screen and (max-width:375px) {
    .heading {
        bottom: 24% !important;
    }

    .heading-content {
        bottom: 19% !important;
    }
}
@media screen and (max-width:320px) {
   .heading {
       bottom: 30% !important;
   }
    .heading-content {
       bottom: 23% !important;
    }
} */
