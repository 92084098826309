@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap');
html,
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 overflow-x: hidden !important;
 overflow-y: hidden !important;
}

.disable-text-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn {
  border-top-right-radius: 0% !important;
  border-bottom-left-radius: 0% !important;
  border-top-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  background-color: red !important;
  border: none !important;
  box-shadow: 2px 3px 6px #fffcfc !important;
  margin-bottom: 220px;
}

.btn:hover {
  cursor: pointer !important;
  background-color: rgb(0.1, 0, 0, 0) !important;
}
header .logo {
  width: 72px;
  height: auto;
  pointer-events: none;
  align-items: left;
  justify-content: center;
  margin-left: 2%;
  padding-top: 1%;
}
footer{
  padding-right: 20px;
}
footer .logo {
  padding-bottom: 10px;
  width: 42px;
  height: auto;
  align-items: center;
  margin-left: 95%;
}

.App-header {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

.App-header::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 300px;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
  z-index: -1;
}

.App-logo {
  width: 60px;
  height: auto;
  pointer-events: none;
}

h1 {
  /* font-family: "Fjalla One"; */
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  display: inline;
  text-align: left;
  font-size: 5em;
  color: #f0f0f0;
  padding-top: 100px !important;
}

h1 {
  font-size: 60px !important;
}

h2 {
  font-size: 40px !important;
}

p {
  /* font-family: "Roboto", sans-serif; */
  font-family: 'Rubik', sans-serif;
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.8em;
}

.btn--sound {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9;
  background: transparent;
  box-shadow: none;
  border: none;
  color: rgba(255, 255, 255, 0.4);
  display: none;
}

.btn--sound span {
  font-size: 80px !important;
  font-weight: 200;
}

/* footer */

footer {
  position: fixed;
  height: 200px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  cursor: pointer;
  /* pointer-events: cursor; */
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  pointer-events: cursor;
  z-index: 9;
}
